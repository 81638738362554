import { Bars3Icon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { Link as LinkScroll } from "react-scroll";

const Nav = ({ openNav }) => {
  const [activeSection, setActiveSection] = useState("");

  const handleScroll = () => {
    const sections = [
      "about",
      "education",
      "uni-project",
      "cur-project",
      "contact",
    ];
    let maxVisibleHeight = 0;
    let currentSection = "";

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element) {
        const rect = element.getBoundingClientRect();
        const visibleHeight =
          Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);

        if (visibleHeight > maxVisibleHeight) {
          // Find the section with the maximum visible height
          maxVisibleHeight = visibleHeight;
          currentSection = section;
        }
      }
    });

    if (currentSection !== activeSection) {
      setActiveSection(currentSection);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]); // eslint-disable-line

  return (
    <div className="w-[100%] fixed z-[10000] top-0 h-[12vh] bg-[#141c27] shadow-md">
      <div className="flex items-center justify-between w-[80%] mx-auto h-[100%]">
        <h1 className="flex-[0.6] text-[25px] text-white font-bold">
          Edison
          <span
            style={{
              background: "linear-gradient(to top, #D32F2F 0%, #FBC02D 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {" "}
            Yang
          </span>
        </h1>
        {["about", "uni-project", "cur-project", "education", "contact"].map(
          (item) => (
            <LinkScroll
              key={item}
              to={item}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-88}
              className={`nav-link ${
                activeSection === item ? "border-b-4 border-orange-400" : ""
              }`}
            >
              {item.charAt(0).toUpperCase() + item.slice(1)}
            </LinkScroll>
          )
        )}
        <div onClick={openNav}>
          <Bars3Icon className="w-[2rem] md:hidden h-[2rem] cursor-pointer text-orange-400" />
        </div>
      </div>
    </div>
  );
};

export default Nav;

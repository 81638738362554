import React, { useEffect } from "react";
import "./TestSphere.css";

import TagCloud from "TagCloud";

const TextSphere = () => {
  useEffect(() => {
    // dont use return if deploy
    const texts = [
      "Java",
      "React",
      "C",
      "GitHub Actions",
      "Docker",
      "Spring Boot",
      "JavaScript",
      "TypeScript",
      "NodeJS",
      "DevOps",
      "AWS",
      "Python",
      "MERN Stack",
      "MySQL",
      "GitHub",
      "MongoDB",
      "C#",
      "Heroku",
      ".Net Core",
    ];

    const options = {
      radius: 200,
      maxSpeed: "normal",
      initSpeed: "normal",
      keep: true,
    };

    TagCloud(".tagcloud", texts, options);

    // return () => {
    //   const texts = [
    //     "Java",
    //     "React",
    //     "C",
    //     "GitHub Actions",
    //     "Docker",
    //     "Spring Boot",
    //     "JavaScript",
    //     "Node",
    //     "DevOps",
    //     "AWS",
    //     "Python",
    //     "MERN Stack",
    //     "MySQL",
    //     "GitHub",
    //     "MongoDB",
    //     "C#",
    //     "Heroku",
    //   ];

    //   const options = {
    //     radius: 200,
    //     maxSpeed: "normal",
    //     initSpeed: "normal",
    //     keep: true,
    //   };

    //   TagCloud(".tagcloud", texts, options);
    // };
  }, []);

  return (
    <>
      <div className="text-sphere">
        <span className="tagcloud"></span>
      </div>
    </>
  );
};

export default TextSphere;

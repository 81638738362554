import { ArrowDownTrayIcon } from "@heroicons/react/16/solid";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import InstagramIcon from "@mui/icons-material/Instagram";
import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";

const Footer = ({ id }) => {
  return (
    <div id={id} className="pt-[2rem] pb-[4rem] bg-[#02050a] lg:h-[88vh]">
      <div className="w-[80%] mx-auto mt-[6rem] mb-[2rem] md:mb-[6rem]">
        <div className="flex flex-col items-center">
          <h1
            className="text-[20px] font-bold mb-4 md:text-[30px]"
            style={{
              background: "linear-gradient(to top, #D32F2F 0%, #FBC02D 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Like what you have seen?
          </h1>
          <h1
            className="text-[20px] font-bold mb-4 text-center md:text-[30px]"
            style={{
              background: "linear-gradient(to top, #0072ff 0%, #ff9100 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Let's Work Together to Make Something Happen!
          </h1>
          <div className="flex flex-col items-center space-y-4">
            <a
              href="https://drive.usercontent.google.com/u/0/uc?id=1KJiZwyLztAbt42HSidkhWQ1WMiC_zAhh&export=download"
              download="Lishun_Yang_CV.pdf"
            >
              <button
                className="px-8 hover:bg-[#8A2BE2] transition-all 
        duration-200 py-3 text-[14px] font-bold uppercase mt-4 mb-4
        bg-[#55e6a5] text-black flex items-center justify-center space-x-2 w-full md:w-60 md:text-[18px]"
              >
                Download CV
                <ArrowDownTrayIcon className="w-5 h-5 ml-2 text-black md:w-6 md:h-6" />
              </button>
            </a>
            <a href="mailto:edisonyls@hotmail.com">
              <button
                className="px-8 hover:bg-[#8A2BE2] transition-all 
        duration-200 py-3 text-[14px] font-bold uppercase mt-4 mb-4
        bg-[#55e6a5] text-black flex items-center justify-center space-x-2 w-full md:w-60 md:text-[18px]"
              >
                Contact Me
                <EnvelopeIcon className="w-5 h-5 ml-2 text-black md:w-6 md:h-6" />
              </button>
            </a>
          </div>
          <hr className="w-[80%] border-t border-white opacity-25 mt-8" />
          <div className="flex justify-center space-x-6 mt-4">
            <a
              href="https://www.instagram.com/leesianyong/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon
                style={{ fontSize: "60px" }}
                className="text-white m-3 p-2 rounded-full cursor-pointer hover:bg-gray-600 hover:bg-opacity-50 transition duration-300"
              />
            </a>
            <a
              href="https://github.com/edisonyls"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon
                style={{ fontSize: "60px" }}
                className="text-white m-3 p-2 rounded-full cursor-pointer hover:bg-gray-600 hover:bg-opacity-50 transition duration-300"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/lishun-yang-152aa01b8/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon
                style={{ fontSize: "60px" }}
                className="text-white m-3 p-2 rounded-full cursor-pointer hover:bg-gray-600 hover:bg-opacity-50 transition duration-300"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="w-[80%] mt:0 mx-auto grid grid-cols-1 md:grid-cols-2 items-center justify-between mb-[3rem] md:mt-[2rem]">
        <div className="text-[16px] mb-[2rem] md:mb-0 text-white opacity-20">
          Lishun Yang @ 1999 | Programmer Passionate About Solutions
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import EducationItem from "./EducationItem";
import SkillItem from "./SkillItem";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";

const longSkills = [
  "Spring Boot",
  "JavaScript",
  "GitHub Actions",
  "MERN Stack",
];

const shortSkills = [
  "Java",
  "React",
  "GitHub",
  "AWS",
  "C",
  "Python",
  "Docker",
  "Node",
  "DevOps",
  "MySQL",
  "Heroku",
  "Angular",
];

function getColorAndTextColor(skill) {
  const colors = [
    "#FF5733",
    "#C70039",
    "#900C3F",
    "#581845",
    "#3498DB",
    "#1ABC9C",
    "#16A085",
    "#2ECC71",
    "#F1C40F",
    "#F39C12",
    "#E67E22",
    "#D35400",
    "#34495E",
    "#2C3E50",
    "#22313F",
    "#6C7A89",
  ];

  function getLuminance(hex) {
    const rgb = hex
      .replace("#", "")
      .match(/.{1,2}/g)
      .map((x) => parseInt(x, 16) / 255)
      .map((x) => (x <= 0.03928 ? x / 12.92 : ((x + 0.055) / 1.055) ** 2.4));
    return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
  }

  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  const luminance = getLuminance(randomColor);
  const textColor = luminance > 0.179 ? "#000000" : "#FFFFFF";

  return { color: randomColor, textColor };
}

const Skills = ({ id }) => (
  <div
    id={id}
    className="pt-[4rem]  pb-[3rem] px-[2rem] bg-[#0C0017] md:pt-[6rem] md:h-[88vh] md:pb-[5rem] md:px-[10%]"
  >
    <h1 className="heading">
      Edu<span className="text-[#fb923c]">Cation</span>
    </h1>
    <div className="mx-auto pt-[2rem] md:pt-[4rem] grid  gap-[2rem] items-start justify-center md:grid-cols-[1fr,3rem,1fr]">
      <div className="flex flex-col space-y-[2rem]">
        <EducationItem
          title="The University of Melbourne"
          year="2022 - 2023"
          degree="Master of Software Engineering"
          subDegree="HCI"
          src="/images/unimelb.png"
          alt="unimelb logo"
          aos="fade-right"
          delay="200"
        />
        <EducationItem
          title="The University of Melbourne"
          year="2019 - 2021"
          degree="Bachelor of Science"
          subDegree="Computing and Software Systems"
          src="/images/unimelb.png"
          alt="unimelb logo"
          aos="fade-right"
          delay="400"
        />
      </div>

      <div className="bg-white ml-[-2px] w-[3px] h-[33%] self-center rounded-full" />

      <div data-aos="fade-left" className="pl-[2rem] gap-1 self-center">
        <div className="flex items-center self-center">
          <p className="text-orange-400 text-center text-[1.3rem] md:text-[2rem] mr-2">
            Skills
          </p>
          <StackedLineChartIcon
            className="text-orange-400 text-[2rem]"
            style={{ fontSize: "20px" }}
          />
        </div>
        <div className="grid grid-cols-4">
          {shortSkills.map((skill) => {
            const { color, textColor } = getColorAndTextColor(skill);
            return (
              <SkillItem
                key={skill}
                name={skill}
                color={color}
                textColor={textColor}
              />
            );
          })}
        </div>

        <div className="grid grid-cols-2 mt-2">
          {longSkills.map((skill) => {
            const { color, textColor } = getColorAndTextColor(skill);
            return (
              <SkillItem
                key={skill}
                name={skill}
                color={color}
                textColor={textColor}
                style={{ gridColumn: "span 3" }}
              />
            );
          })}
        </div>
      </div>
    </div>
  </div>
);

export default Skills;

import React from "react";

const SkillItem = ({ name, color, textColor }) => {
  return (
    <div className="mr-3 mt-4 rounded-xl ">
      <span
        className="px-[1rem] py-[0.4rem] text-[17px] inline-block rounded-lg w-full text-center"
        style={{ backgroundColor: color, color: textColor }} // Apply the colors here
      >
        {name}
      </span>
    </div>
  );
};

export default SkillItem;

import React from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";

const About = ({ id }) => {
  return (
    <div
      id={id}
      className="bg-[#121212] pb-[5rem] h-auto pt-[4rem] md:pt-[6rem] md:pb-[7rem]"
    >
      <div className="grid grid-cols-1 pt-[3rem] md:grid-cols-[3fr,1fr] w-[80%] mx-auto gap-[3rem] items-center">
        <div>
          <h1 className="text-[20px] font-bold mb-2 uppercase text-[#55e6a5] md:text-[1rem]">
            ABOUT ME
          </h1>
          <h2
            className="text-[25px] md:text-[35px] lg:text-[45px] md:leading-[3rem] 
                  leading-[2rem] capitalize mb-[2rem] font-bold text-white"
          >
            MSE <span className="text-[#fb923c]">GRADUATE</span>
          </h2>
          <div className="mb-[3rem] flex items-center md:space-x-10">
            <span className="w-[5px] hidden md:block h-[280px] bg-[#55e6a5] rounded-sm" />
            <div className="text-[19px] text-slate-300 w-[100%]">
              <p>
                I graduated with a Master's in Software Engineering from the
                University of Melbourne last December. I love tackling coding
                challenges and always aim for clean, efficient solutions.
                Throughout my academic journey, I have successfully collaborated
                on diverse software engineering projects, both on-site and
                remotely, demonstrating strong communication skills and
                adaptability to new challenges.
              </p>
              <br />
              <p>
                I am eager to explore opportunities which allow me to further
                utilize and expand my skills in a dynamic software engineering
                role, and I believe that I will definitely make something
                happen!
              </p>
            </div>
          </div>
          <div
            className="mt-[2rem] flex-col space-y-6 sm:space-y-0 sm:flex
                            sm:flex-row items-center sm:space-x-6"
          >
            <a
              href="https://drive.usercontent.google.com/u/0/uc?id=1KJiZwyLztAbt42HSidkhWQ1WMiC_zAhh&export=download"
              download="Lishun_Yang_CV.pdf"
            >
              <button
                className="px-[2rem] hover:bg-[#8A2BE2] transition-all 
                duration-200 py-[1rem] text-[18px] font-bold uppercase
                bg-[#55e6a5] text-black flex items-center space-x-2"
              >
                Download CV
                <ArrowDownTrayIcon className="w-[1.6rem] h-[1.7rem] ml-2 text-black" />
              </button>
            </a>
          </div>
        </div>
        <div
          data-aos="fade-left"
          className="lg:w-[400px] mx-auto md:ml-[5rem] lg:ml-[5rem] mt-[2rem] lg:mt-0 lg:h-[400px] w-[260px] h-[260px] relative"
        >
          <img
            src="/images/me.jpeg"
            alt="user"
            fill
            sizes="100%"
            className="relative z-[11] w-[100%] h-[100%] object-cover rounded-full"
          />
          <div className="absolute w-[100%] h-[100%] z-[10] bg-[#55e6a5] top-[-1.5rem] right-[-1.5rem] rounded-full hidden md:block"></div>
        </div>
      </div>
    </div>
  );
};

export default About;

import { useEffect, useState } from "react";
import MobileNav from "./MobileNav.jsx";
import Nav from "./Nav.jsx";
import Hero from "./Hero.jsx";
import About from "./About.jsx";
import AOS from "aos";
import "aos/dist/aos.css";
import CurProjects from "./CurProjects.jsx";
import Skills from "./Skills.jsx";
import Footer from "./Footer.jsx";
import UniProjects from "./UniProjects.jsx";

function App() {
  const [nav, setNav] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  const openNav = () => setNav(true);
  const closeNav = () => setNav(false);

  function handleNavigate(sectionId) {
    setActiveSection(sectionId);
    console.log(activeSection);
    const element = document.getElementById(sectionId);
    if (element) {
      // Calculate the navbar's height as a percentage of the viewport height
      const navbarHeight = window.innerHeight * 0.12;
      const yOffset = -navbarHeight; // Negative value to move the scroll position up
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      // Execute the scrolling with smooth behavior
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="home" className="overflow-x-hidden">
      <div>
        {/* Navbar */}
        <MobileNav nav={nav} closeNav={closeNav} onNavigate={handleNavigate} />
        <Nav openNav={openNav} />
        <Hero />
        <div className="relative z-[30]">
          <About id="about" />
          <UniProjects id="uni-project" />
          <CurProjects id="cur-project" />
          <Skills id="education" />
          <Footer id="contact" />
        </div>
      </div>
    </div>
  );
}

export default App;

import React, { useState, useEffect, useRef } from "react";

const projectData = [
  {
    title: "Apromore Project",
    technologies: "Spring Boot, React, MySQL, Docker",
    description: [
      "Created a comprehensive solution to address identified challenges related to business process compliance by developing a plugin for the Apromore portal.",
      "Leading the DevOps team to implement the CI/CD pipelines using GitHub Actions.",
      "Work as a full-stack programmer to implement various features for the company, such as upload risk or obligation registers from a CSV, link the type of controls template to the risk/obligation, upload event logs with CSV file to each process, and being able to view the content of each event log file and etc.",
    ],
  },
  {
    title: "Personal CRM",
    technologies: "ReactJS, NodeJS",
    description: [
      "Worked in a group of five as a full stack programmer to create a web-based application of a personal CRM by using MERN stack.",
      "Designed and developed user-friendly pages for user to keep track of their current and incoming events, manage their contacts and send emails to their contacts.",
      "Used JWT authentication method and used OAuth2 to send emails via Gmail.",
      "Deployed the webpage through Heroku and store all the user's data in MongoDB.",
      "Implemented continuous integration (CI) pipeline with GitLab to automate backend API testing.",
    ],
  },
  {
    title: "Prediction of Cooking Time",
    technologies: "Python",
    description: [
      "Used supervised machine learning methods to predict the cooking time for recipes based on their steps, ingredients and other features.",
      "Utilized Python libraries like NumPy, Pandas and ScikitLearn to perform data pre-processing and visualization.",
      "Analyzed performance with statistical methods and has an accuracy around 82%.",
    ],
  },
  {
    title: "Pet Social Media App",
    technologies: "React Native, Spring Boot, MySQL, Redis",
    description: [
      "Implements secure sign-in and email verification.",
      "Features a Home screen for browsing posts, a GPS and gyroscope-enabled Map screen for real-time location tracking, and a Notifications screen for alerts on user interactions.",
      "Provides in-app messaging and user profile customization.",
      "Enables users to create and interact with posts on missing or found pets, and general pet discussions.",
      "Utilizes GPS, gyroscope, and camera sensors to support enhanced app functionalities.",
    ],
  },
];

const UniProjects = ({ id }) => {
  const [selectedProject, setSelectedProject] = useState(projectData[0]);
  const [animateTitle, setAnimateTitle] = useState(false);
  const [animateTech, setAnimateTech] = useState(false);
  const [animateDesc, setAnimateDesc] = useState(false);
  const [eyeRotation, setEyeRotation] = useState(0);
  const dogRef = useRef(null); // Ref for the dog image

  function angle(cx, cy, ex, ey) {
    const dy = ey - cy;
    const dx = ex - cx;
    const rad = Math.atan2(dy, dx); // Angle in radians
    const deg = (rad * 180) / Math.PI; // Convert radians to degrees
    return deg;
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (dogRef.current) {
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        const rekt = dogRef.current.getBoundingClientRect();
        const anchorX = rekt.left + rekt.width / 2;
        const anchorY = rekt.top + rekt.height / 2;
        const angleDeg = angle(mouseX, mouseY, anchorX, anchorY);
        setEyeRotation(angleDeg + 90);
      }
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handleProjectClick = (project) => {
    if (project !== selectedProject) {
      setAnimateTitle(false);
      setAnimateTech(false);
      setAnimateDesc(false);
      setTimeout(() => {
        setSelectedProject(project);
        setAnimateTitle(true);
      }, 10);
    }
  };

  useEffect(() => {
    setAnimateTitle(true);
  }, []);

  useEffect(() => {
    if (animateTitle) {
      setTimeout(() => setAnimateTech(true), 300);
    }
  }, [animateTitle]);

  useEffect(() => {
    if (animateTech) {
      setTimeout(() => setAnimateDesc(true), 300);
    }
  }, [animateTech]);

  return (
    <div
      id={id}
      className="bg-[#0C0017] pt-[4rem] pb-[5rem] px-[2rem] md:px-[10%] md:pt-[6rem] md:pb-[8rem]"
    >
      <h1 className="heading">
        University <span className="text-[#fb923c]">Projects</span>
      </h1>
      <div
        data-aos="fade-right"
        className="flex flex-col mt-[4rem] md:pl-[6rem] h-auto md:pr-[6rem] md:flex-row "
      >
        <div className="md:w-1/4 mt-[2rem]">
          {projectData.map((project, index) => (
            <button
              key={index}
              className={`text-left py-2 px-4 w-full text-[18px] relative ${
                project === selectedProject
                  ? "text-[#55e6a5] border-l-4 border-[#55e6a5]"
                  : "text-white hover:bg-gray-900 hover:text-[#55e6a5]"
              }`}
              onClick={() => handleProjectClick(project)}
            >
              {project.title}
            </button>
          ))}
        </div>
        <div
          className="project-details text-white pl-[4rem] pr-[4rem] rounded-md relative mt-10 lg:h-[48vh] md:w-3/4"
          style={{ position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              right: 0, // Position to the right side
              top: "-7%", // Center vertically relative to the container
              transform: "translateY(-50%)", // Centering adjustment
              width: "100%",
              overflow: "visible",
            }}
          >
            <img
              ref={dogRef}
              src="images/dog.png"
              alt="Cartoon"
              className="w-[30%] h-[auto] hidden lg:block"
            />
            <div>
              <img
                src="images/eye.png"
                alt="Left Eye"
                className="eye absolute w-[3%] h-[auto] hidden lg:block"
                style={{
                  top: "39%",
                  left: "10.4%",
                  transform: `rotate(${eyeRotation}deg)`,
                  transformOrigin: "center",
                }}
              />
              <img
                src="images/eye.png"
                alt="Right Eye"
                className="eye absolute w-[3%] h-[auto] hidden lg:block"
                style={{
                  top: "41.8%",
                  left: "16.5%",
                  transform: `rotate(${eyeRotation}deg)`,
                  transformOrigin: "center",
                }}
              />
            </div>
          </div>
          <h2
            className={`font-semibold text-[28px] mt-[2rem] ${
              animateTitle ? "fade-right-enter" : "fade-right-initial"
            }`}
          >
            {selectedProject.title}
          </h2>
          <h3
            className={`text-md text-[#fb923c] mt-4 mb-4 text-[18px] ${
              animateTech ? "fade-right-enter" : "fade-right-initial"
            }`}
          >
            🔥 Technologies: {selectedProject.technologies}
          </h3>
          <ul
            className={`list-disc pl-5 mb-[2rem] ${
              animateDesc ? "fade-right-enter" : "fade-right-initial"
            }`}
          >
            {selectedProject.description.map((desc, index) => (
              <li key={index} className="mt-1 mb-2">
                {desc}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UniProjects;

import React from "react";

const EducationItem = ({
  title,
  year,
  degree,
  subDegree,
  src,
  alt,
  aos,
  delay,
}) => {
  return (
    <div
      data-aos={aos}
      data-aos-delay={delay}
      className="mb-4 text-center mx-auto max-w-[none]"
    >
      <div className="flex items-center justify-center space-x-4 mb-4">
        <span
          className="px-[1rem] text-[#55e6a5] py-[0.4rem] font-bold text-[18px] 
          border-[2px] border-[#55e6a5] inline-block rounded-lg"
        >
          {year}
        </span>
        <img src={src} alt={alt} className="w-[70px] h-[70px] " />
      </div>
      <h1
        className="uppercase font-semibold mb-[1rem] text-[16px] sm:text-[18px] 
      md:text-[24px] text-white"
      >
        {title}
      </h1>
      <p className="text-[#aaaaaa] font-normal text-[20px] opacity-80">
        {degree}
      </p>
      <p className="text-[#aaaaaa] font-normal text-[20px] opacity-80">
        {subDegree}
      </p>
    </div>
  );
};

export default EducationItem;

import { ArrowDownTrayIcon, XMarkIcon } from "@heroicons/react/16/solid";
import React from "react";

const MobileNav = ({ nav, closeNav, onNavigate }) => {
  const handleNavigation = (section) => {
    onNavigate(section);
    closeNav(); // Close the navigation menu after selecting a section
  };

  const navAnimation = nav ? "translate-x-0" : "translate-x-[-100%]";

  return (
    <div
      className={`fixed ${navAnimation} transform transition-all duration-300 top-0 left-0 
      right-0 bottom-0 z-[1000000000] bg-[#09101a]`}
    >
      <div className="w-[100vw] h-[100vh] flex flex-col items-center justify-center">
        <div
          className="nav-link-mobile"
          onClick={() => handleNavigation("home")}
        >
          HOME
        </div>
        <div
          className="nav-link-mobile"
          onClick={() => handleNavigation("about")}
        >
          ABOUT
        </div>
        <div
          className="nav-link-mobile"
          onClick={() => handleNavigation("uni-project")}
        >
          UNI-PROJECT
        </div>
        <div
          className="nav-link-mobile"
          onClick={() => handleNavigation("cur-project")}
        >
          CUR-PROJECT
        </div>
        <div
          className="nav-link-mobile"
          onClick={() => handleNavigation("education")}
        >
          EDUCATION
        </div>
        <div
          className="nav-link-mobile"
          onClick={() => handleNavigation("contact")}
        >
          CONTACT
        </div>
        <a
          href="https://drive.usercontent.google.com/u/0/uc?id=1KJiZwyLztAbt42HSidkhWQ1WMiC_zAhh&export=download"
          download="Lishun_Yang_CV.pdf"
        >
          <button
            className="px-[2rem] hover:bg-[#8A2BE2] transition-all 
                duration-200 py-[1rem] text-[18px] font-bold uppercase
                bg-[#55e6a5] text-black flex items-center space-x-2"
          >
            Download CV
            <ArrowDownTrayIcon className="w-[1.6rem] h-[1.7rem] ml-2 text-black" />
          </button>
        </a>
      </div>
      <div
        onClick={closeNav}
        className="absolute z-[1000000000] cursor-pointer top-[2rem] right-[2rem] w-[3rem]
                h-[3rem] text-orange-400"
      >
        <XMarkIcon />
      </div>
    </div>
  );
};

export default MobileNav;

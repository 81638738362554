import React, { useState } from "react";

const CurProjects = ({ id }) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div
      id={id}
      className="bg-[#121212] pt-[4rem] h-auto md:pt-[6rem] md:pb-[5rem] pb-[3rem] px-[2rem] md:px-[10%] "
    >
      <p className="heading">
        Current <span className="text-orange-400">Projects</span>
      </p>
      <div className="grid grid-cols-1 w-[90%] mx-auto items-stretch gap-[3rem] mt-[4rem] text-white mb-[2rem] lg:grid-cols-2">
        <div data-aos="zoom-in" className="flex flex-col">
          <div
            className="bg-orange-900 hover:scale-110 transform transition-all 
              duration-300 font-semibold p-[2rem] flex flex-col flex-grow"
            onMouseEnter={() => setShowVideo(true)}
            onMouseLeave={() => setShowVideo(false)}
          >
            {showVideo ? (
              <video
                src="/videos/ylslc.mp4"
                autoPlay
                loop
                muted
                className="w-full h-[250px] flex-shrink-0 object-cover"
              />
            ) : (
              <img
                src="/images/ylslc.jpeg"
                alt="Backend"
                className="w-full h-[250px] flex-shrink-0 object-cover"
              />
            )}
            <h1 className="text-center uppercase text-[25px] mt-[1.5rem] mb-[1.5rem] flex-shrink-0">
              LeetCode Recorder
            </h1>
            <p className="text-[15px] text-[#d3d2d2] font-normal flex-grow">
              This is an online website application that you can record every
              leetcode problem you have done. By having all the necessary
              statistics recorded in one place, you can easily see your
              performance improvements during days, weeks, and months.
            </p>

            <button
              onClick={() => window.open("http://www.ylslc.org", "_blank")}
              className="bg-[#55e6a5] text-black px-4 py-2 mt-[1.5rem] self-center rounded w-[60%] hover:bg-emerald-100"
            >
              Go Live
            </button>
          </div>
        </div>
        <div data-aos="zoom-in" data-aos-delay="300" className="flex flex-col">
          <div
            className="bg-purple-900 hover:scale-110 transform transition-all 
  duration-300 font-semibold p-[2rem] flex flex-col flex-grow"
          >
            <img
              src="/images/coding.jpg"
              alt="coding"
              className="w-full h-[250px] flex-shrink-0 object-cover"
            />
            <h1 className="text-[25px] text-center uppercase mt-[1.5rem] mb-[1.5rem] flex-shrink-0">
              Memo. (Reminder App)
            </h1>
            <p className="text-[15px] text-[#d3d2d2] font-normal flex-grow">
              This is a creative and innovative mobile application that my
              friends and I are currently developing that will be different from
              most of the reminder apps in the market. We intended to integrate
              machine learning and large language modelling into this
              application to provide a more personalized reminder service for
              users.
            </p>
            <div className="mt-4 flex justify-center space-x-4">
              <button
                className="bg-[#55e6a5] bg-opacity-40 text-black self-center w-[60%] px-4 py-2 rounded cursor-not-allowed"
                disabled
              >
                Go Live
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurProjects;

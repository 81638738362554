import { ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import { TypeAnimation } from "react-type-animation";

const TextEffect = () => {
  return (
    <div className="flex items-center">
      <ChevronDoubleRightIcon className="w-[2rem] h-[2rem] ml-2 mr-2 text-white md:w-[3rem] md:h-[3rem]" />
      <TypeAnimation
        sequence={[
          // Same substring at the start will only be typed out once, initially
          "Programmer",
          1500, // wait 1s before replacing "Mice" with "Hamsters"
          "Collaborator",
          1500,
          "Problem Solver",
          1500,
          "Innovator",
          1500,
        ]}
        speed={50}
        className="text-[30px]  md:text-[45px] font-bold uppercase"
        style={{
          background: "linear-gradient(to top, #0072ff 0%, #ff9100 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
        repeat={Infinity}
      />
    </div>
  );
};

export default TextEffect;
